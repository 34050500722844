<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1" style="background-image: url('/images/order/order_01.jpg')">
        <div class="content animate__animated animate__fadeIn">
          <div class="title1">沧穹科技<span>产品定制</span></div>
          <div class="title2">
            品质硬件基础实施至行业应用解决方案<br />
            完善的产品体系，助力企业高效智能化管理水平
          </div>
        </div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content orderSucess">
          <div style="width: 100%; text-align: center"><img src="/images/order/success.png" style="width: 256px; height: 280px" /></div>
          <div class="succInfo">
            <span class="info1">恭喜您，订购成功！</span>
            <br />
            <span class="info2">我们已收到您的订购需求，客服会尽快与您联系，请保持电话畅通！</span>
            <br />
            <el-button type="success" icon="el-icon-view" size="small" class="detailBtn" @click="detailOrder">查看订单</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {};
  },
  mounted() {
    // console.log(this.$route.query);
  },
  methods: {
    detailOrder() {
      this.$router.push({ path: "/order/search", query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "order.scss";
.wrapper-content .row_content {
  background-color: #fff;
  .orderSucess {
    height: 500px;
    padding-top: 20px;
    .succInfo {
      font-size: 18px;
      margin-top: -35px;
      width: 100%;
      text-align: center;
      color: #444;
      line-height: 30px;
      .info2 {
        font-size: 16px;
        color: #666;
      }
      .detailBtn {
        margin: 30px 0;
        background-color: #31b57e;
        border: 1px solid #619ca1;
        color: white;
      }
    }
  }
}
</style>
